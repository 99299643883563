import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { RiMailSendLine } from "react-icons/ri"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import { BsPersonCheck } from "react-icons/bs"

import { FaViber } from "react-icons/fa"

const RuStepsMed = () => {
  const data = useStaticQuery(graphql`
    query {
      one: contentfulAsset(
        file: { fileName: { eq: "alexandre-boucher-CoUogz3Udak-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      two: contentfulAsset(
        file: { fileName: { eq: "scott-graham-OQMZwNd3ThU-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      three: contentfulAsset(
        file: {
          fileName: { eq: "samuele-errico-piccarini-qAc3UNF8Hm4-unsplash.jpg" }
        }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `)
  const backgroundFluidOne = [
    data.one.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidTwo = [
    data.two.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidThree = [
    data.three.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  return (
    <section className="steps container py-5">
      <div className="title2 py-5 text-uppercase">Как работает центр?</div>
      <div className="row">
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidOne}>
            <RiMailSendLine />
          </BackgroundImage>
          <p className="step-title">Отправьте документы</p>
          <p className="step-description grayscale-hover">
            Получите бесплатную консультацию, пришлите сканы или фото документов
            на аудит в&nbsp;
            <a
              className="viber"
              href="viber://chat/?number=%2B380683328808"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <FaViber /> Viber
            </a>
            , или на электронную почту.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidTwo}>
            <HiOutlineDocumentDuplicate />
          </BackgroundImage>
          <p className="step-title">Подготовка лицензионного дела</p>
          <p className="step-description grayscale-hover">
            Мы заполняем документы и ведомости про состояние
            материально-технической базы за 3-5 дней, согласуем с вами для
            подачи в МОЗ, и за вашей подписью подаем в единое окно МОЗ.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidThree}>
            <BsPersonCheck />
          </BackgroundImage>
          <p className="step-title">Получите документы и работайте</p>
          <p className="step-description grayscale-hover">
            После позитивного решения МОЗ оплачиваете услуги Центра Лицензий и
            государственный платеж в размере одного прожиточного минимума (2684
            грн на январь 2023).
          </p>
        </div>
      </div>
    </section>
  )
}

export default RuStepsMed
